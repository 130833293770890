<template>
<div>
  <div class="box p-6 has-text-centered">
    VERIFYING INFORMATION PLEASE WAIT....
  </div>
</div>
</template>
<script>
export default {

  created() {
    this.$store.dispatch('billing/verifyNewCard', {
      transactionId: this.$route.query.id
    }).then(card => {
      this.$store.commit('auth/setCard', card)
      this.$toast.success('Card Successfully Verified')
      this.$router.push({
        name: 'dashboard'
      })
    }, error => {
      this.$toast.error(error.response.data)
    })
  }
}
</script>